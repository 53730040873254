import flatpickr from "flatpickr"
import "bootstrap";
require('select2');

$(document).on('turbolinks:load', function() {
    var s,
        base = {

            settings: {

            },

            init: function() {
                s = this.settings;
                this.bindUIActions();
            },

            bindUIActions: function() {
                this.initActionOnBrowserBack();
                this.initAllRangeDatePicker();
                this.searchAppointmentByStatus();
                this.actionOnSearchKey();
                this.initDatePickerType1();
                this.openPopupImage();
                this.forgotPassword();
                this.validatePhoneNumber();
                this.toggleChangeThePassword();
                this.numberFieldValidator();
                this.charactersFieldValidator();
                this.handleDateRangeFilter();
                this.handleTableSearchInput();
                this.validateRequiredInput();
                this.initDatePickerTypeCompAssesssmentValidFrom();
                this.initDatePickerTypeCompAssesssmentValidUpto();
                this.initcompAssesssmentStatus();
                this.copyText();
            },

            copyText: function () {
                $(document).on('click', '.copy-btn', function () {
                    let copyText = $(this).data('copy-text');
                    let afterCopyText = $(this).data('after-copy-text');

                    navigator.clipboard.writeText(copyText);
                    $(this).find('small').text('Copied');

                    setTimeout(() => {
                        $(this).find('small').text(afterCopyText);
                    }, 3000);
                })
            },

            initActionOnBrowserBack: function() {
                window.addEventListener("pageshow", function(event) {
                    var historyTraversal = event.persisted ||
                        (typeof window.performance != "undefined" &&
                            window.performance.getEntriesByType("navigation")[0].type === "back_forward");
                    if (historyTraversal) {
                        // Handle page restore.
                        window.location.replace(window.location.href);
                    }
                });
            },

            initAllRangeDatePicker: function() {
                flatpickr("[data-behaviour='flat-date']", {
                    dateFormat: "d-m-Y",
                    allowInput: true
                })
            },

            validatePhoneNumber: function() {
                $(document).on('input', '.phone-number', function() {
                    var number = $(this).val().replace(/[^\d]/g, '')
                    if (number.length == 7) {
                        number = number.replace(/(\d{3})(\d{4})/, "$1-$2");
                    } else if (number.length == 10) {
                        number = number.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
                    }
                    $(this).val(number)
                })
            },

            initDatePickerType1: function() {
                flatpickr("[data-behaviour='date-picker-type1']", {
                    dateFormat: "m-d-Y",
                    allowInput: true,
                    minDate: "today",
                })
            },

            initDatePickerTypeCompAssesssmentValidFrom: function() {
                flatpickr("[data-behaviour='date-picker-comp-assesssment-valid-from']", {
                    dateFormat: "m/d/Y",
                    allowInput: true,
                    minDate: "today",
                    defaultDate: "today",
                    onChange: function () {
                        flatpickr("[data-behaviour='date-picker-comp-assesssment-valid-upto']", {
                            dateFormat: "m/d/Y",
                            allowInput: false,
                            minDate: new Date($("[data-behaviour='date-picker-comp-assesssment-valid-from']").val()).fp_incr(364),
                            defaultDate: new Date($("[data-behaviour='date-picker-comp-assesssment-valid-from']").val()).fp_incr(364),
                            clickOpens: false,
                        })
                    }
                })
            },

            initDatePickerTypeCompAssesssmentValidUpto: function() {
                flatpickr("[data-behaviour='date-picker-comp-assesssment-valid-upto']", {
                    dateFormat: "m/d/Y",
                    allowInput: false,
                    minDate: new Date().fp_incr(364),
                    defaultDate: new Date().fp_incr(364),
                    altInput: false,
                    clickOpens: false,
                })
            },

            initcompAssesssmentStatus: function () {
                $('#details_status').click(function() {
                    $('#details_status').val($("#details_status").is(":checked"));
                    console.log()
                    if($("#details_status").is(":checked") == true) {
                        $('#details_status').attr('checked', true);
                    } else {
                        $('#details_status').removeAttr('checked');
                    }
                });
            },


            searchAppointmentByStatus: function() {
                $(document).on('change', '.dynamic-on-select-form-submission', function() {
                    $(this).parents('form').submit();
                });
            },

            initializeSelect2: function() {
                $('.select2-dynamic').select2();
            },

            actionOnSearchKey: function() {
                $(".search-key").keypress(function(e) {
                    if (e.which == 13) {
                        $(this).closest('form').submit();
                    }
                });

                $(".search-key").on('input', function() {
                    if ($(".search-key").val().length == 0) {
                        var uri = window.location.toString();
                        if (uri.indexOf("?") > 0) {
                            var clean_uri = uri.substring(0, uri.indexOf("?"));
                            if(clean_uri.includes("/admin/agents")) {
                                window.history.replaceState({}, document.title, clean_uri + "?active=1")
                            } else {
                                window.history.replaceState({}, document.title, clean_uri);
                            }
                            
                        }
                        window.location.reload();
                    }
                });
            },

            handleDateRangeFilter: function() {
                var self = this;
                flatpickr("[data-behaviour='date-filter-range']", {
                    mode: "range",
                    dateFormat: "m/d/Y",
                    showDropdowns: true,
                    autoUpdateInput: false,
                    autoApply: true,

                    onChange: ([start, end]) => {
                        if (start && end) {
                            let startDate = self.getFormattedDate(start);
                            let endDate = self.getFormattedDate(end)
                            console.log({ startDate, endDate });
                            $('.search-key-date').val(`${startDate} to ${endDate}`);
                            $('.table-search').submit();
                        }
                    },
                });

                if ($('.search-key-date').val() && $('.search-key-date').val().length > 0)
                    $('.search-key-date').val($('.search-key-date').data('current-date'));

            },

            handleTableSearchInput: function(event) {
                $(document).on('change', '.table-search-input', function() {
                    $('.table-search').submit();
                })
            },

            getFormattedDate: function(date) {
                let year = date.getFullYear();
                let month = (1 + date.getMonth()).toString().padStart(2, '0');
                let day = date.getDate().toString().padStart(2, '0');

                return month + '/' + day + '/' + year;
            },

            openPopupImage: function() {
                $(document).on("click", ".preview-image", function() {
                    var imageURL = $(this).data("img-href")

                    $("#imagePopup").modal('show');

                    $("#imagePopup").find(".modal-body")
                        .html("<a target='_blank' href=" + `${imageURL}` + "><img src=" + `${imageURL}` + " style='width: 46rem'></a>")
                });
            },

            forgotPassword: function() {
                $(document).on("click", ".forgot-password-link", function(e) {
                    e.preventDefault();
                    var email = $(this).parents('form').find('#user_email').val();
                    var btn = $(this);

                    $.ajax({
                        type: "GET",
                        url: "/api/v1/forgot_password?email=" + email,
                        dataType: "json",
                        beforeSend: function() {
                            btn.addClass('disabled');
                            btn.parents('form').find('.form-messages').html("");
                        },
                        success: function(data) {
                            btn.parents('form').find('.form-messages').html(`<div class="alert alert-success" role="alert">${data.message}</div>`)
                            btn.parents('form').find('#user_email').val('')
                            btn.removeClass('disabled');
                        },
                        error: function(data) {
                            btn.parents('form').find('.form-messages').html(`<div class="alert alert-danger" role="alert">${data.responseJSON.error || data.responseJSON.message}</div>`)
                            btn.removeClass('disabled');
                        }
                    });
                })
            },

            toggleChangeThePassword: function() {
                $(document).on('click', '.toggle-password', function() {

                    $(this).toggleClass("fa-eye fa-eye-slash");

                    var input = $(this).parent().find('input');
                    input.attr('type') === 'password' ? input.attr('type', 'text') : input.attr('type', 'password')
                });
            },

            numberFieldValidator: function() {
                $(document).on('input', '.number-field', function() {
                    var number = $(this).val().replace(/[^\d]/g, '');
                    $(this).val(number);
                })
            },

            charactersFieldValidator: function() {
                $(document).on('input', '.chars-field', function() {
                    var string = $(this).val().replace(/[^a-zA-Z_ ]/g, '');
                    $(this).val(string);
                })
            },

            validateRequiredInput: function() {

                $(document).on('input', 'input', function() {
                    let inputVal = $(this).val();
                    let input = $(this);

                    if (input.attr('required') === 'required' || input.attr('required') === true) {
                        if (inputVal.trim() === "" || inputVal === null) {
                            input.val('');
                        }
                    }
                })

            }
        }

    base.init();
})